import { ref, computed, onUnmounted } from 'vue'
import { template } from '@shein/common-function'
import { daEventCenter } from '@shein-aidc/basis-sa-event-center'
import { debuggerLog } from 'public/src/pages/checkout/utils.js'

export const useDelectBubble = (item) => {
  const delectBubbleVisible = ref(false)
  const timer = ref(null)
  const bubbleRef = ref(null)

  const delectBubbleInfo = computed(() => {
    const bubbleType = ['wonderful_comment', 'rankList' ]
    let bubble = {}
    for(let i = 0; i < bubbleType.length; i++){
      const current = bubbleType[i]
      bubble = item?.m2nBubbleLure?.find(s => s?.type == current) ?? {}
      if(bubble?.type) break
    }
    const { guideTip, appBuriedPoint, ...other } = bubble?.commentTag ?? {}
    const replaceText = guideTip?.replaceText?.[0]
    let result = null
    if(replaceText){
      const text = template(`<span style="color:${replaceText.color};font-weight:${replaceText.strong == 1 ? 700 : 400}">${replaceText.text}</span>`, guideTip.tip)
      result = { 
        text,
        icon: bubble.icon,
        appBuriedPoint,
        type: bubble?.type ?? '',
        ...other
      }
    }
    return result
  })

  // 商品减少气泡曝光埋点
  const delectBubblePoint = computed(() => {
    let result = {
      comments_tag: delectBubbleInfo.value.appBuriedPoint,
      content: 'ugc_tags'
    }
    if(delectBubbleInfo.value.type === 'rankList'){
      const { rankListIdentifier = '', boardGenerateType = '' } = delectBubbleInfo.value.rankListBuriedPoint ?? {}
      result = {
        content: 'ranking_list_label',
        src_module: 'trade_display',
        ranking_list_identifier: rankListIdentifier,
        board_generate_type: boardGenerateType,
      }
    }
    return result
  })

  const log = (...args) => {
    debuggerLog('useDelectBubble::', args )
  }

  const changeDelectBubble = (val = false) => {
    if(val && delectBubbleInfo.value){
      timerBubble('end')
      timerBubble('start')
      window && window.addEventListener('scroll', handleScroll)
    }else{
      debuggerLog('useDelectBubble::', '触发关闭' )
      timerBubble('end')
    }
  }

  const timerBubble = (type = 'start') => {
    const map = {
      start: () => {
        delectBubbleVisible.value = true
        timer.value = setTimeout(() => {
          changeDelectBubble(false)
        }, 2000)
      },
      end: () => {
        delectBubbleVisible.value = false
        if(timer.value){
          log('销毁定时器')
          clearTimeout(timer.value)
          timer.value = null
        }
      }
    }
    return map[type]()
  }

  const handleScroll = () => {
    timerBubble('end')
  }

  const close = () => {
    daEventCenter.triggerNotice({
      id: 'click_ugctags_bubble:simple',
      data: delectBubblePoint.value
    })
  }

  onUnmounted(() => {
    window && window.removeEventListener('scroll', handleScroll)
  })

  return {
    delectBubbleVisible, changeDelectBubble, delectBubbleInfo, bubbleRef, close, delectBubblePoint
  }
}
