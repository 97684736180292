import { onMounted, computed } from 'vue'
import { useMapState, useMapMutation as useMapMutations } from 'public/src/pages/store_pages/store/mapHook'
import { template } from '@shein/common-function'

export const useQuickShipInit = () => {
  const { checkout, quickShipInfoTag, qsMallList, language, checkoutScene } = useMapState([ 'checkoutScene', 'language', 'checkout', 'quickShipInfoTag', 'storeData', 'buyNowNo', 'qsMallList' ])
  const { assignState } = useMapMutations(['assignState'])

  const quickShip = async () => {
    const { OrderQuickshipStyle } = checkout.value.checkoutBFFAbtInfo   || {}
    const orderQuickshipStyleStatus = OrderQuickshipStyle?.param?.OrderQuickshipStyleStatus == 1
    if(!quickShipInfoTag.value?.qsDataProps && !quickShipInfoTag.value?.qsDataNoLabelProps){
      const { last_quickship_tag  } = qsMallList.value ?? {}
      const qsDataProps = {
        isShowNewStyle: orderQuickshipStyleStatus, // 是否展示新样式，即带有icon + x 日达 | 老样式
        text: last_quickship_tag || '', // qs标签标题（前半部分内容）
        noLableStyle: false, // 是否需要lable标签样式
        labelStyle: { margin: 0 },
        // qs标签时效信息（后半部分内容）1.新样式 2. isLocationTop = false 3.tip有值
        qsTimelinessInfo: {
          isLocationTop: true,
        }
      }
      const qsDataNoLabelProps = { ...qsDataProps, noLableStyle: true }
      assignState({ quickShipInfoTag: {
        qsDataProps,
        qsDataNoLabelProps
      },
      })
    }
  }

  onMounted(() => {
    if(checkoutScene.value == 'CheckoutAgain'){
      const qsDataProps = {
        isShowNewStyle: true, // 是否展示新样式，即带有icon + x 日达 | 老样式
        text: language.value.SHEIN_KEY_PC_23485 || '', // qs标签标题（前半部分内容）
        noLableStyle: false, // 是否需要lable标签样式
        labelStyle: { margin: 0 },
        // qs标签时效信息（后半部分内容）1.新样式 2. isLocationTop = false 3.tip有值
        qsTimelinessInfo: {
          isLocationTop: true,
        }
      }
      const qsDataNoLabelProps = { ...qsDataProps, noLableStyle: true }
      assignState({ quickShipInfoTag: {
        qsDataProps,
        qsDataNoLabelProps
      } })
    } else {
      quickShip()
    }
  })
  return {}
}

export const useGetGuickShip = ({ mallCode } = {}) => {
  const { quickShipInfoTag, qsMallList, checkout, language, defaultShipping } = useMapState([ 'quickShipInfoTag', 'qsMallList', 'descQuickShipTime', 'checkout', 'language', 'defaultShipping' ])

  // 对部分qs时效提示进行内容组装
  const qsReplenishDes = computed(() => {
    const { qs_time_same, last_quickship_tag, combine_transport_time_desc_replace  } = qsMallList.value || {}
    const qsReplenishDesAbt = checkout.value.checkoutBFFAbtInfo?.TradeTransStoreTest?.param?.TradeTransStoreTestStatus || 0

    let result = ''
    const item = defaultShipping.value?.find(f => f?.mall_code == mallCode) || defaultShipping.value?.[0] || {}

    if(item.desc_quick_ship_time){
      result = item.desc_quick_ship_time.replace(/script/g, '').replace(/\s/g, ' ').replace(/&nbsp;/g, ' ')
      last_quickship_tag && (result = item.desc_quick_ship_time.replace(/{QSname}/g, `${last_quickship_tag}`)
        .replace(/\[time\]/g, `${item.quick_ship_time}`))
    }
    // 对部分qs时效提示进行内容组装
    if(qs_time_same == 0){
      result = qsReplenishDesAbt == 0 ? template(last_quickship_tag, language.value.SHEIN_KEY_PC_33031) : result
    }

    // 不受店铺时效是否存在差异的影响，为2直接取字段
    result = qsReplenishDesAbt == 2 ? combine_transport_time_desc_replace || result || '' : result
    return result
  })

  return { quickShipInfoTag, qsMallList, qsReplenishDes }
}
